import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ReactJson from 'react-json-view';
import dayjs from 'dayjs';
import useLoadingPromise from '../../hooks/useLoadingPromise';
import { getCustomerEvents } from '../../services/stripe.service';

const EventsModal = ({ enterpriseId, show, onHide }) => {
  const { t } = useTranslation();
  const [events, setEvents] = useState([]);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [openJSON, setOpenJSON] = useState(false);
  const { waitWithLoad, isLoading } = useLoadingPromise();

  const fetchEvents = async () => {
    const response = await getCustomerEvents(enterpriseId);
    if (response.success) {
      setEvents(response.data);
    }
  };

  const handleJSONModal = (event) => () => {
    setCurrentEvent(event);
    setOpenJSON(true);
  };

  useEffect(() => {
    if (show && enterpriseId) {
      waitWithLoad(fetchEvents());
    } else {
      setEvents([]);
    }
  }, [show, enterpriseId]);

  return (
    <>
      <Modal
        size="xl"
        centered
        show={openJSON}
        onHide={() => { setCurrentEvent(null); setOpenJSON(false); }}
      >
        <Modal.Header closeButton>
          {currentEvent?.type}
        </Modal.Header>
        <Modal.Body>
          <ReactJson src={currentEvent} />
        </Modal.Body>
      </Modal>
      <Modal size="xl" centered show={show} onHide={onHide}>
        <Modal.Header closeButton>
          {t('EnterpriseSettings.Stripe.events')}
        </Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center flex-column">
              <p>
                {t('EnterpriseSettings.Stripe.EventsModal.loading')}
              </p>
              <Spinner animation="border" />
            </div>
          ) : null}
          {!isLoading && !events.length ? (
            t('EnterpriseSettings.Stripe.EventsModal.noEvents')
          ) : null}
          {events.length && !isLoading ? (
            <div className="mb-3">
              {t('EnterpriseSettings.Stripe.EventsModal.description')}
            </div>
          ) : null}
          <ul>
            {events?.map((event) => (
              <li key={event.id}>
                <p>
                  (
                  {dayjs.unix(event.created).format('YYYY-MM-DD HH:mm:ss')}
                  )
                  &nbsp;
                  {event.data?.object?.object}
                  &nbsp;
                  <span onClick={handleJSONModal(event)} style={{ color: 'blue' }} className="pointer">
                    [
                    {event.type}
                    ]
                  </span>
                </p>
              </li>
            ))}
          </ul>

        </Modal.Body>
      </Modal>
    </>
  );
};

EventsModal.propTypes = {
  enterpriseId: PropTypes.string,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

EventsModal.defaultProps = {
  enterpriseId: null,
};

export default EventsModal;
