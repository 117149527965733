import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { cloneDeep, isNil } from 'lodash';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Form,
  Modal,
  Row,
  Col,
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import Tag from '../Tag/Tag';
import LabelValueLine from '../LabelValueLine/LabelValueLine';
import { AWColors, formatDate } from '../../helpers/utils';
import { updateEnterpriseSettings } from '../../services/user.service';
import { AlertContext } from '../../contexts/alert.context';
import AWSelect from '../AWSelect/AWSelect';
import { closeEnterprise, getEnterprise, updateEnterprise } from '../../services/enterprise.service';
import ActionButton from '../ActionButton/ActionButton';
import AWLink from '../AWLink/AWLink';
import EnterpriseIbans from './EnterpriseIbans';
import { FileContext } from '../../contexts/file.context';
import { search } from '../../services/document.service';
import { getSignedURL } from '../../services/file.service';
import VoucherModal from '../VoucherModal/VoucherModal';
import EventsModal from '../EventsModal/EventsModal';

const Settings = {
  CentralizedPanel: 'centralizedPanel',
  CentralizedSchema: 'centralizedSchema',
  CentralizedPartnership: 'centralizedPartnership',
  DocTypesManagement: 'docTypesManagement',
  DocTypesAddworkingExternal: 'docTypesAddworkingExternal',
  HeadQuarterPanel: 'headQuarterPanel',
  MutualizedPanel: 'mutualizedPanel',
  PartnershipAdd: 'partnership-add',
  PartnershipInvite: 'partnership-invite',
  Languages: 'languages',
  PartnershipProcess: 'partnership-process',
  HideDashboard: 'hide-dashboard',
  HideMissions: 'hide-missions',
  HideWorkfields: 'hide-workfields',
  HideEvaluations: 'hide-evaluations',
  Iban: 'iban',
  Billing: 'billing',
  OnlyMeContractList: 'onlyMeContractList',
  ApprovalsEnabled: 'approvalsEnabled',
  NoPaymentOption: 'no_payment_option',
  Contract: 'contract',
  EvaluationCriteria: 'evaluationCriteria',
};

const defaultLanguages = [
  { value: 'fr-FR', label: 'fr-FR' },
  { value: 'en-EN', label: 'en-EN' },
  { value: 'de-DE', label: 'de-DE' },
];

const queryboyOptions = {
  fields: [
    'id',
    'name',
    'identification_number',
    'is_vendor',
    'is_customer',
    'created_at',
    'parent_id',
    'deleted_at',
    'no_payment_option',
  ],
  disableDefaultFilters: true,
  page_size: 100,
};

const EnterpriseSettings = ({ enterprise, setEnterprise, onChangeCode }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [settings, setSettings] = useState(enterprise.settings);
  const { setNotif } = useContext(AlertContext);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showIbansModal, setshowIbansModal] = useState(false);
  const [showButtonSogetrelSurvey, setShowButtonSogetrelSurvey] = useState(false);
  const [voucherModal, setVoucherModal] = useState(false);
  const [eventsModal, setEventsModal] = useState(false);
  const [fileSogetrelSurvey, setFileSogetrelSurvey] = useState();
  const [submitCode, setSubmitCode] = useState(false);
  const [subscriptionCode, setSubscriptionCode] = useState(enterprise.subscriber?.code ?? '');
  const { setFile } = useContext(FileContext);

  useEffect(async () => {
    const survey = await search(
      'files',
      {
        search: { name: { $like: `${enterprise.id}-questionnaire-fournisseur-sogetrel` } },
      },
      {
        limit: 1,
        orderBy: { created_at: 'DESC' },
      },
    );
    if (survey?.success) {
      setShowButtonSogetrelSurvey(true);
      setFileSogetrelSurvey(
        Array.isArray(survey.data) && survey.data.length ? survey.data[0].id : survey.data?.id,
      );
    }
  }, [enterprise]);

  const updateSettings = async (_settings) => {
    const res = await updateEnterpriseSettings(enterprise.id, _settings);
    res.displayNotif(setNotif);
    setSettings(res.hasError() ? enterprise.settings : res.data);
  };

  const handleClickShowIbans = () => {
    setshowIbansModal(true);
    setFile({});
  };

  const handleEnterpriseChange = (fieldName) => async ({ target: { checked } }) => {
    const res = await updateEnterprise(enterprise.identification_number, { [fieldName]: checked });
    if (res.success) {
      setEnterprise((e) => ({ ...e, [fieldName]: checked }));
    }
  };

  const handleSubscriberCode = (e) => {
    setSubscriptionCode(e.target.value);
  };

  const handleChange = (setting) => async ({ target: { checked } }) => {
    let _settings = cloneDeep(settings);
    if (Settings.CentralizedPanel === setting && checked) {
      _settings = {
        ..._settings,
        centralizedPanel: true,
        mutualizedPanel: false,
        headQuarterPanel: false,
      };
    } else if (Settings.MutualizedPanel === setting) {
      _settings = {
        ..._settings,
        mutualizedPanel: !!checked,
        centralizedPanel: checked ? false : _settings.centralizedPanel,
        headQuarterPanel: !checked ? checked : _settings.headQuarterPanel,
      };
    } else if (Settings.PartnershipAdd === setting) {
      _settings = { ..._settings, partnership: { ..._settings.partnership, add: checked } };
    } else if (Settings.PartnershipInvite === setting) {
      _settings = { ..._settings, partnership: { ..._settings.partnership, invite: checked } };
    } else if (Settings.PartnershipProcess === setting) {
      _settings = { ..._settings, partnership: { ..._settings.partnership, process: checked } };
    } else if (Settings.HideDashboard === setting) {
      _settings = { ..._settings, hideV1: { ..._settings.hideV1, dashboard: checked } };
    } else if (Settings.HideWorkfields === setting) {
      _settings = { ..._settings, hideV1: { ..._settings.hideV1, workfields: checked } };
    } else if (Settings.HideEvaluations === setting) {
      _settings = { ..._settings, hideV1: { ..._settings.hideV1, evaluations: checked } };
    } else if (Settings.HideMissions === setting) {
      _settings = { ..._settings, hideV1: { ..._settings.hideV1, missions: checked } };
    } else {
      _settings = { ..._settings, [setting]: checked };
    }
    await updateSettings(_settings);
  };

  const handleLangChange = async (options) => {
    await updateSettings({
      ...settings,
      languages: options.map((l) => l.value),
    });
  };

  useEffect(() => {
    setSettings(enterprise.settings);
  }, [enterprise?.settings]);

  useEffect(() => {
    setSubscriptionCode(enterprise.subscriber?.code ?? '');
  }, [enterprise.subscriber?.code]);

  useEffect(() => {
    setSubmitCode(
      subscriptionCode?.trim() !== enterprise.subscriber?.code?.trim(),
    );
  }, [subscriptionCode, enterprise.subscriber?.code]);

  const handleCloseEnterprise = () => {
    setShowConfirmationModal(true);
  };

  const handleSeeEnterpriseUpgrades = () => {
    history.push(`/enterprises/${enterprise.id}`);
  };

  const handleClose = () => {
    setshowIbansModal(false);
  };

  const handleConfirmAction = async () => {
    await closeEnterprise(enterprise.id);
    const fetchedEnterprise = await getEnterprise(enterprise.id, queryboyOptions);
    fetchedEnterprise.displayNotif(setNotif);
    if (fetchedEnterprise.success) {
      setEnterprise(fetchedEnterprise.data);
      setShowConfirmationModal(false);
    }
  };

  const handleDownloadSogetrelSurvey = async () => {
    if (fileSogetrelSurvey) {
      const res = await getSignedURL(fileSogetrelSurvey);
      if (res.success) {
        const response = await fetch(res.data.signedUrl);
        const blob = await response.blob();
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        let ext = 'pdf';
        if (res.data.mimeType?.startsWith('image')) {
          ext = res.data.mimeType.replace('image/', '');
        }
        a.download = `survey-sogetrel-${enterprise.id}.${ext}`;
        a.click();
      }
    }
  };

  const handleCancelAction = () => {
    setShowConfirmationModal(false);
  };

  const settingsBlock = () => (
    <Col xl={4} lg={6} md={12} className="mb-3">
      <Card>
        <Card.Body>
          <div className="d-flex">
            <h5>{t('General.settings')}</h5>
          </div>

          <Form.Group className="mb-2">
            <Form.Label className="label">
              {t('Enterprise.Settings.languages')}
            </Form.Label>
            <AWSelect
              options={defaultLanguages}
              value={languages}
              isMulti
              onChange={handleLangChange}
            />
          </Form.Group>
        </Card.Body>
      </Card>
    </Col>
  );

  const isHeadQuarter = useMemo(() => (
    settings?.headQuarter === enterprise.id
    || (isNil(settings?.headQuarter) && !enterprise.parent_id)
  ), [enterprise.settings, enterprise.parent_id]);

  const isProvider = useMemo(() => (
    enterprise?.is_vendor && !enterprise?.is_customer
  ), [enterprise.is_customer, enterprise.is_vendor]);

  const isPartnershipDisabled = useMemo(() => (
    !isHeadQuarter && settings?.centralizedPartnership
  ), [isHeadQuarter, settings]);

  const languages = useMemo(() => (
    settings?.languages.map((l) => ({ value: l, label: l })) || []
  ), [settings?.languages]);

  return (
    <div className="enterprise-settings">
      <EventsModal
        enterpriseId={enterprise?.id}
        onHide={() => setEventsModal(false)}
        show={eventsModal}
      />
      <EnterpriseIbans onClose={handleClose} show={showIbansModal} ibans={enterprise.ibans} />
      <VoucherModal
        onClose={() => setVoucherModal(false)}
        show={voucherModal}
        enterpriseId={enterprise.id}
      />
      {!enterprise.deleted_at ? (
        <div>
          <Modal show={showConfirmationModal} centered>
            <Modal.Header className="d-flex justify-content-between">
              <p>{t('EnterpriseSettings.closeEnterprise')}</p>
            </Modal.Header>
            <Modal.Body>
              <p>{t('EnterpriseSettings.confirmClose')}</p>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
              <ActionButton type="button" onClick={handleCancelAction}>
                {t('Common.back', 'Retour')}
              </ActionButton>
              <ActionButton
                backgroundColor="#12957D"
                color="white"
                onClick={handleConfirmAction}
                type="button"
              >
                {t('Common.validate', 'Valider')}
              </ActionButton>
            </Modal.Footer>
          </Modal>
          <div className="d-flex mb-2 justify-content-end bgcolor-red">
            {enterprise.is_customer ? (
              <Button
                variant="success"
                className="mx-2"
                onClick={() => setVoucherModal(true)}
              >
                {t('Voucher.associate')}
              </Button>
            ) : null}
            {enterprise.seeDataUpgrades ? (
              <Button onClick={handleSeeEnterpriseUpgrades}>
                {t('EnterpriseSettings.seeEnterpriseUpgrades')}
              </Button>
            ) : null}
            {showButtonSogetrelSurvey && (
              <Button variant="info" className="mx-2" onClick={handleDownloadSogetrelSurvey}>
                {t('EnterpriseSettings.sogetrelSurvey')}
              </Button>
            )}
            {!enterprise.is_customer && enterprise.subscriber?.stripe_id ? (
              <Button className="mx-2" onClick={() => setEventsModal(true)}>
                {t('EnterpriseSettings.Stripe.events')}
              </Button>
            ) : null}
            <Button variant="danger" onClick={handleCloseEnterprise}>
              {t('EnterpriseSettings.closeEnterprise')}
            </Button>
          </div>
        </div>
      ) : (
        <div className="d-flex mb-2 justify-content-end">
          <p>
            {t('EnterpriseSettings.enterpriseClosedUntil')}
            &nbsp;
            {formatDate(enterprise.deleted_at)}
          </p>
        </div>
      )}
      <Row>
        <Col xl={4} lg={6} md={12} className="mb-3">
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-between mb-3">
                <Card.Title>{enterprise.name}</Card.Title>
                <Card.Subtitle className="text-muted">{enterprise.identification_number}</Card.Subtitle>
              </div>
              <Card.Subtitle className="mb-3 font-12 label text-muted">{enterprise.id}</Card.Subtitle>
              <div className="mb-3 d-flex mr-2">
                {
                enterprise.is_customer
                  ? (
                    <Tag
                      size="tiny"
                      color={AWColors.Green}
                      label={t('SurveyGeneral.Answerers.customer')}
                    />
                  )
                  : ''
              }
                {
                enterprise.is_vendor
                  ? (
                    <Tag
                      size="tiny"
                      color={AWColors.Red}
                      label={t('SurveyGeneral.Answerers.provider')}
                    />
                  ) : ''
              }
              </div>
              <LabelValueLine className="mb-2" label={`${t('Enterprise.Settings.headQuarter')} ?`}>
                <span className="mr-10px">
                  {isHeadQuarter ? t('Filter.yes') : t('Filter.no')}
                </span>
              </LabelValueLine>
              {enterprise?.is_vendor ? (
                <div className="mb-2">
                  <AWLink
                    className="text-muted"
                    onClick={handleClickShowIbans}
                  >
                    {t('Enterprise.Iban.viewIbans')}
                  </AWLink>
                </div>
              ) : null}

            </Card.Body>
          </Card>
        </Col>

        {!enterprise.is_customer ? (
          <>
            <Col xl={4} lg={6} md={12} className="mb-3">
              <Card>
                <Card.Body>
                  <h5>
                    {t('Enterprise.Settings.Subscription.subscription')}
                  </h5>

                  <LabelValueLine
                    className="mb-2"
                    label={t('Enterprise.Settings.Subscription.isSubscriptionActive')}
                  >
                    {enterprise.subscription?.status === 'active' ? t('Filter.yes') : t('Filter.no')}
                  </LabelValueLine>

                  <LabelValueLine
                    className="mb-2"
                    label={t('Enterprise.Settings.Subscription.siren')}
                  >
                    {enterprise.country === 'fr' ? enterprise.identification_number?.slice(0, 9) : ''}
                  </LabelValueLine>

                  <LabelValueLine
                    className="mb-2"
                    label={t('Enterprise.Settings.Subscription.stripeId')}
                  >
                    {enterprise.subscriber?.stripe_id}
                  </LabelValueLine>

                  <LabelValueLine
                    className="mb-2"
                    label={t('Enterprise.Settings.Subscription.created_by')}
                  >
                    {enterprise.subscriber?.created_by_user
                      ? `${enterprise.subscriber?.created_by_user.firstname} ${enterprise.subscriber.created_by_user.lastname} (${enterprise.subscriber.created_by_user.email})`
                      : enterprise.subscriber?.created_by}
                  </LabelValueLine>

                  <LabelValueLine
                    className="mb-2"
                    // boxClassName=""
                    label={(
                      <>
                        {t('Enterprise.Settings.Subscription.code')}
                      </>
                    )}
                  >
                    <div className="d-flex">
                      {submitCode ? (
                        <Button
                          variant="success"
                          onClick={() => { onChangeCode(subscriptionCode); }}
                          className="me-2"
                        >
                          <FontAwesomeIcon icon={faCheck} />
                        </Button>
                      ) : null}
                      <Form.Control
                        value={subscriptionCode}
                        onChange={handleSubscriberCode}
                        disabled={enterprise.subscription?.status === 'active'}
                      />
                    </div>
                  </LabelValueLine>
                </Card.Body>
              </Card>
            </Col>
            {settingsBlock()}
          </>
        ) : null}

        {enterprise?.is_customer ? (
          <>
            <Col xl={4} lg={6} md={12} className="mb-3">
              <Card>
                <Card.Body>
                  <h5>{t('Enterprise.Categories.providers')}</h5>
                  <LabelValueLine className="mb-2" label={t('Enterprise.Settings.centralizedPanel')}>
                    {/* {enterprise.settings?.centralizedPanel ? 'oui' : 'non'} */}
                    <Form.Check
                      type="switch"
                      className={isHeadQuarter && !isProvider ? 'switch-pointer' : ''}
                      checked={settings?.centralizedPanel}
                      onChange={handleChange(Settings.CentralizedPanel)}
                      disabled={!isHeadQuarter || isProvider}
                    />
                  </LabelValueLine>
                  <LabelValueLine className="mb-2" label={t('Enterprise.Settings.mutualizedPanel')}>
                    <Form.Check
                      type="switch"
                      className={isHeadQuarter && !isProvider ? 'switch-pointer' : ''}
                      checked={settings?.mutualizedPanel}
                      onChange={handleChange(Settings.MutualizedPanel)}
                      disabled={!isHeadQuarter || isProvider}
                    />
                  </LabelValueLine>
                  <LabelValueLine className="mb-2" label={t('Enterprise.Settings.headQuarterPanel')}>
                    <Form.Check
                      type="switch"
                      className={isHeadQuarter && !isProvider ? 'switch-pointer' : ''}
                      checked={settings?.headQuarterPanel}
                      onChange={handleChange(Settings.HeadQuarterPanel)}
                      disabled={!isHeadQuarter || !settings?.mutualizedPanel || isProvider}
                    />
                  </LabelValueLine>
                  <LabelValueLine className="mb-2" label={t('Enterprise.Settings.centralizedSchema')}>
                    <Form.Check
                      type="switch"
                      className={isHeadQuarter && !isProvider ? 'switch-pointer' : ''}
                      checked={settings?.centralizedSchema}
                      onChange={handleChange(Settings.CentralizedSchema)}
                      disabled={!isHeadQuarter || isProvider}
                    />
                  </LabelValueLine>
                </Card.Body>
              </Card>
            </Col>

            <Col xl={4} lg={6} md={12} className="mb-3">
              <Card>
                <Card.Body>
                  <div className="d-flex">
                    <h5>{t('Enterprise.Settings.HideV1.title')}</h5>
                  </div>

                  <LabelValueLine className="mb-2" label={t('Enterprise.Settings.HideV1.dashboard')}>
                    <Form.Check
                      type="switch"
                      className="switch-pointer"
                      checked={settings?.hideV1?.dashboard}
                      onChange={handleChange(Settings.HideDashboard)}
                      disabled={isProvider}
                    />
                  </LabelValueLine>
                  <LabelValueLine className="mb-2" label={t('Enterprise.Settings.HideV1.workfields')}>
                    <Form.Check
                      type="switch"
                      className="switch-pointer"
                      checked={settings?.hideV1?.workfields}
                      onChange={handleChange(Settings.HideWorkfields)}
                      disabled={isProvider}
                    />
                  </LabelValueLine>
                  <LabelValueLine className="mb-2" label={t('Enterprise.Settings.HideV1.missions')}>
                    <Form.Check
                      type="switch"
                      className="switch-pointer"
                      checked={settings?.hideV1?.missions}
                      onChange={handleChange(Settings.HideMissions)}
                      disabled={isProvider}
                    />
                  </LabelValueLine>
                  <LabelValueLine className="mb-2" label={t('Enterprise.Settings.HideV1.evaluations')}>
                    <Form.Check
                      type="switch"
                      className="switch-pointer"
                      checked={settings?.hideV1?.evaluations}
                      onChange={handleChange(Settings.HideEvaluations)}
                      disabled={isProvider}
                    />
                  </LabelValueLine>
                </Card.Body>
              </Card>
            </Col>
          </>
        ) : null}
      </Row>

      {enterprise?.is_customer ? (
        <Row>
          <Col xl={4} lg={6} md={12} className="mb-3">
            <Card>
              <Card.Body>
                <div className="d-flex">
                  <h5>{t('Enterprise.Settings.billing')}</h5>
                </div>

                <LabelValueLine
                  className="mb-2"
                  label={t('Enterprise.Settings.billing')}
                >
                  <Form.Check
                    type="switch"
                    className={!isProvider ? 'switch-pointer' : ''}
                    checked={settings?.billing}
                    onChange={handleChange(Settings.Billing)}
                    disabled={isProvider}
                  />
                </LabelValueLine>
                <LabelValueLine
                  className="mb-2"
                  label={t('Enterprise.Settings.noPaymentOption')}
                >
                  <Form.Check
                    type="switch"
                    className="switch-pointer"
                    checked={enterprise.no_payment_option}
                    onChange={handleEnterpriseChange(Settings.NoPaymentOption)}
                  />
                </LabelValueLine>
                <LabelValueLine className="mb-2" label={t('Enterprise.Settings.iban')}>
                  <Form.Check
                    type="switch"
                    className={!isProvider ? 'switch-pointer' : ''}
                    checked={settings?.iban}
                    onChange={handleChange(Settings.Iban)}
                    disabled={isProvider}
                  />
                </LabelValueLine>
              </Card.Body>
            </Card>
          </Col>

          <Col xl={4} lg={6} md={12} className="mb-3">
            <Card>
              <Card.Body>
                <div className="d-flex">
                  <h5>{t('Enterprise.Settings.Partnership.title')}</h5>
                </div>

                <LabelValueLine
                  className="mb-2"
                  label={t('Enterprise.Settings.centralizedPartnership')}
                >
                  <Form.Check
                    type="switch"
                    className={isHeadQuarter && !isProvider ? 'switch-pointer' : ''}
                    checked={settings?.centralizedPartnership}
                    onChange={handleChange(Settings.CentralizedPartnership)}
                    disabled={
                      !isHeadQuarter
                      || isProvider
                      || (!isHeadQuarter && settings?.centralizedPanel)
                    }
                  />
                </LabelValueLine>

                <div className="d-flex justify-content-between align-items-center flex-wrap">
                  <div className="label mb-2">{t('Enterprise.Settings.Partnership.title')}</div>
                  <div className="d-flex flex-wrap">
                    <Form.Group className="d-flex flex-column align-items-center card p-2 ml-15px">
                      <Form.Label className="mr-2 label">
                        {t('Enterprise.Settings.Partnership.add')}
                      </Form.Label>
                      <Form.Check
                        type="switch"
                        className="switch-pointer mt-2"
                        checked={settings?.partnership?.add}
                        onChange={handleChange(Settings.PartnershipAdd)}
                        disabled={
                          isPartnershipDisabled
                          || isProvider
                          || (!isHeadQuarter && settings?.centralizedPanel)
                        }
                      />
                    </Form.Group>
                    <Form.Group className="d-flex flex-column align-items-center card p-2 ml-15px">
                      <Form.Label className="mr-2 label">
                        {t('Enterprise.Settings.Partnership.invite')}
                      </Form.Label>
                      <Form.Check
                        type="switch"
                        className="switch-pointer mt-2"
                        checked={settings?.partnership?.invite}
                        onChange={handleChange(Settings.PartnershipInvite)}
                        disabled={
                          isPartnershipDisabled
                          || isProvider
                          || (!isHeadQuarter && settings?.centralizedPanel)
                        }
                      />
                    </Form.Group>
                    <Form.Group className="d-flex flex-column align-items-center card p-2 ml-15px">
                      <Form.Label className="mr-2 label">
                        {t('Enterprise.Settings.Partnership.process')}
                      </Form.Label>
                      <Form.Check
                        type="switch"
                        className="switch-pointer mt-2"
                        checked={settings?.partnership?.process}
                        onChange={handleChange(Settings.PartnershipProcess)}
                        disabled={
                          isPartnershipDisabled
                          || isProvider
                          || (!isHeadQuarter && settings?.centralizedPanel)
                        }
                      />
                    </Form.Group>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col xl={4} lg={6} md={12} className="mb-3">
            <Card>
              <Card.Body>
                <div className="d-flex">
                  <h5>{t('General.documents')}</h5>
                </div>

                <LabelValueLine
                  className="mb-2"
                  label={t('Enterprise.Settings.docTypesManagement')}
                >
                  <Form.Check
                    type="switch"
                    className={!isProvider ? 'switch-pointer' : ''}
                    checked={settings?.docTypesManagement}
                    onChange={handleChange(Settings.DocTypesManagement)}
                    disabled={isProvider}
                  />
                </LabelValueLine>
                <LabelValueLine
                  className="mb-2"
                  label={t('Enterprise.Settings.docTypesAddworkingExternal')}
                >
                  <Form.Check
                    type="switch"
                    className={!isProvider ? 'switch-pointer' : ''}
                    checked={settings?.docTypesAddworkingExternal}
                    onChange={handleChange(Settings.DocTypesAddworkingExternal)}
                    disabled={!isHeadQuarter || isProvider}
                  />
                </LabelValueLine>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : null}

      <Row>
        {enterprise?.is_customer ? (
          <>
            <Col xl={4} lg={6} md={12} className="mb-3">
              <Card>
                <Card.Body>
                  <div className="d-flex">
                    <h5>{t('General.contracts')}</h5>
                  </div>

                  <LabelValueLine
                    className="mb-2"
                    label={t('Enterprise.Settings.approvalsEnabled')}
                  >
                    <Form.Check
                      type="switch"
                      className={!isProvider ? 'switch-pointer' : ''}
                      checked={settings?.approvalsEnabled}
                      onChange={handleChange(Settings.ApprovalsEnabled)}
                      disabled={!isHeadQuarter || isProvider}
                    />
                  </LabelValueLine>
                  <LabelValueLine
                    className="mb-2"
                    label={t('Enterprise.Settings.onlyMeContractList')}
                  >
                    <Form.Check
                      type="switch"
                      className={!isProvider ? 'switch-pointer' : ''}
                      checked={settings?.onlyMeContractList}
                      onChange={handleChange(Settings.OnlyMeContractList)}
                      disabled={isProvider}
                    />
                  </LabelValueLine>
                  <LabelValueLine
                    className="mb-2"
                    label={t('Enterprise.Settings.contract')}
                  >
                    <Form.Check
                      type="switch"
                      className={!isProvider ? 'switch-pointer' : ''}
                      checked={settings?.contract}
                      onChange={handleChange(Settings.Contract)}
                      disabled={!isHeadQuarter || isProvider}
                    />
                  </LabelValueLine>
                </Card.Body>
              </Card>
            </Col>
            {settingsBlock()}

            <Col xl={4} lg={6} md={12} className="mb-3">
              <Card>
                <Card.Body>
                  <div className="d-flex">
                    <h5>{t('General.evaluations')}</h5>
                  </div>

                  <LabelValueLine
                    className="mb-2"
                    label={t('Enterprise.Settings.evaluationCriteria')}
                  >
                    <Form.Check
                      type="switch"
                      className={!isProvider ? 'switch-pointer' : ''}
                      checked={settings?.evaluationCriteria}
                      onChange={handleChange(Settings.EvaluationCriteria)}
                      disabled={!isHeadQuarter}
                    />
                  </LabelValueLine>
                </Card.Body>
              </Card>
            </Col>

          </>
        ) : null}
      </Row>

    </div>
  );
};

EnterpriseSettings.propTypes = {
  enterprise: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    identification_number: PropTypes.string,
    created_at: PropTypes.string,
    no_payment_option: PropTypes.bool,
    is_vendor: PropTypes.bool,
    parent_id: PropTypes.bool,
    is_customer: PropTypes.bool,
    settings: PropTypes.shape(),
    deleted_at: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    ibans: PropTypes.array,
    seeDataUpgrades: PropTypes.bool,
    subscription: PropTypes.shape(),
    subscriber: PropTypes.shape(),
    country: PropTypes.string,
  }).isRequired,
  setEnterprise: PropTypes.func.isRequired,
  onChangeCode: PropTypes.func,
};

EnterpriseSettings.defaultProps = {
  onChangeCode: () => {},
};

export default EnterpriseSettings;
