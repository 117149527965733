import React, { useContext, useEffect, useState } from 'react';
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header/Header';
import {
  getEnterprise,
  getEnterprises,
  getEnterpriseIbans,
  getEnterpriseWithDifferencesNotAccepted,
} from '../../services/enterprise.service';
import { getEnterpriseSettings, getUserFromId } from '../../services/user.service';
import { NotFound } from '../../helpers/catalog.errors';
import {
  getSubscriber,
  getSubscription,
  upsertSubscription,
} from '../../services/stripe.service';
import {
  BE_IDENTIFICATION_NUMBER_REGEX,
  DE_IDENTIFICATION_NUMBER_REGEX,
  FR_IDENTIFICATION_NUMBER_REGEX,
  UUID_REGEX,
} from '../../helpers/patterns';
import SearchInput from '../../components/SearchBox/SearchInput';
import EnterpriseSettings from '../../components/EnterpriseSettings/EnterpriseSettings';
import useDebounceSearch from '../../hooks/useDebounceSearch';
import { AlertContext } from '../../contexts/alert.context';

const EnterpriseManagement = () => {
  const { t } = useTranslation();
  const [enterprises, setEnterprises] = useState([]);
  const [enterprise, setEnterprise] = useState();
  const [notFound, setNotFound] = useState(false);
  const { setNotif } = useContext(AlertContext);

  const queryboyOptions = {
    fields: [
      'id',
      'name',
      'identification_number',
      'is_vendor',
      'is_customer',
      'country',
      'created_at',
      'parent_id',
      'deleted_at',
      'no_payment_option',
    ],
    disableDefaultFilters: true,
    page_size: 100,
  };

  const queryboyOptionsIbans = {
    fields: [
      'id',
      'enterprise_id',
      'file_id',
      'iban',
      'created_at',
      'updated_at',
      'deleted_at',
      'bic',
      'status',
      'label',
    ],
    disableDefaultFilters: true,
    page_size: 100,
    sort: '-created_at',
  };

  const fetchCreatedByUser = async (createdBy) => {
    const userRes = await getUserFromId(
      createdBy,
      { fields: ['firstname', 'lastname', 'email'] },
    );
    if (userRes.success) {
      return userRes.data;
    }
    return undefined;
  };

  const fetchEnterpriseSubscription = async (_enterprise) => {
    const [subscriptionRes, subscriberRes] = await Promise.all([
      getSubscription(_enterprise.id),
      getSubscriber(_enterprise.id),
    ]);
    let user;
    if (subscriberRes.data?.created_by) {
      user = await fetchCreatedByUser(subscriberRes.data.created_by);
    }
    if (subscriberRes.success) {
      return {
        subscription: subscriptionRes.data,
        subscriber: {
          ...subscriberRes.data ?? {},
          created_by_user: user,
        },
      };
    }
    return {};
  };

  const updateSubscriptionCode = useDebounceSearch(async (code) => {
    if (enterprise?.id) {
      const res = await upsertSubscription(enterprise.id, code);
      res.displayNotif(setNotif);
      if (res?.success) {
        const user = await fetchCreatedByUser(res.data.created_by);
        setEnterprise((e) => ({
          ...e,
          subscriber: {
            ...res.data,
            created_by_user: user,
          },
        }));
      }
    }
  }, 300, [enterprise]);

  const fetchEnterprises = async (search) => {
    const matchings = [
      UUID_REGEX,
      FR_IDENTIFICATION_NUMBER_REGEX,
      DE_IDENTIFICATION_NUMBER_REGEX,
      BE_IDENTIFICATION_NUMBER_REGEX,
    ];
    let needSearch = false;
    if (matchings.some((m) => search.match(m))) {
      const res = await getEnterprise(search, queryboyOptions);
      setEnterprises([res.data]);
      if (res.hasError(NotFound)) needSearch = true;
    } else {
      needSearch = true;
    }
    if (needSearch) {
      const res = await getEnterprises({
        ...queryboyOptions,
        search: { name: { $sw: search.toUpperCase().trim().replace(/\s/g, '_') } },
      });
      if (res.success) {
        setNotFound(false);
        setEnterprises(res.data);
        return res.data;
      }
      if (res.hasError(NotFound)) {
        setNotFound(true);
      }
    }
    return [];
  };

  const fetchSettings = async () => {
    const [res, resIbans, differences] = await Promise.all([
      getEnterpriseSettings(enterprise.id),
      getEnterpriseIbans(enterprise.id, queryboyOptionsIbans),
      getEnterpriseWithDifferencesNotAccepted(enterprise.id),
    ]);
    if (res.success) {
      setEnterprise((e) => ({
        ...e,
        settings: res.data.enterprise,
        ibans: resIbans.data || [],
        seeDataUpgrades: differences.success && !!differences.data?.fields?.length,
      }));
    }
  };

  const handleReset = () => {
    setEnterprises([]);
  };

  const handleClick = async (_enterprise) => {
    const data = await fetchEnterpriseSubscription(_enterprise);
    setEnterprise({
      ..._enterprise,
      ...data,
    });
  };

  useEffect(() => {
    if (enterprise?.id && !enterprise.settings) {
      fetchSettings();
    }
  }, [enterprise]);

  return (
    <div className="h-100 d-flex flex-column">
      <Header />
      <Container fluid className="pt-5 px-5">
        <Row>
          <Col xs={12} md={{ span: 4, offset: 4 }}>
            <SearchInput
              label={t('Enterprise.title')}
              placeholder={t('Enterprise.placeholderSearch')}
              search={fetchEnterprises}
              data={enterprises}
              onReset={handleReset}
              onClick={handleClick}
            />
          </Col>
          <Col xs={12} className="pt-5">
            {
              notFound ? (
                <span className="d-flex justify-content-center">
                  {t('ApiErrors.NotFound')}
                </span>
              ) : ''
            }
            {
              enterprise ? (
                <EnterpriseSettings
                  enterprise={enterprise}
                  setEnterprise={setEnterprise}
                  onChangeCode={updateSubscriptionCode}
                />
              ) : ''
            }
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EnterpriseManagement;
